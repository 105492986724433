.delete {
  &_header {
    text-align: center;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 20px;

    span {
      color: rgba(0, 0, 0, .8)
    }
  }

  &__buttons {
    display: flex;
    column-gap: 20px;

    button {
      flex: 1 1 auto;
    }
  }
}