.wrapper {

  &__video {
    display: flex;

    &__input {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 0;
      border: 1px solid rgba(0, 0, 0, 0.3);
      height: 480px;
      margin-right: 30px;
      overflow: hidden;


      &__preview {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      input {
        display: none;
      }
    }

    &__settings {
      position: relative;
      min-width: 400px;
      flex: 0 0 35vw;

      &__equipment {
        z-index: 10;
      }

      &__coach {
        z-index: 9;
      }

      &__body_part {
        z-index: 8;
      }

      &__workout_style {
        z-index: 7;
      }

      &__difficulty, &__calories, &__hr {
        label {
          display: block;
        }

        input {
          width: 100%;
        }
      }

      &__equipment, &__coach, &__body_part, &__workout_style, &__title {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  &__parts {
    margin-top: 30px;

    &__tile {
      input {
        width: 100%;
      }

      &__header {
        display: flex;
        align-items: center;
        column-gap: 20px;

        button {
          white-space: nowrap;
        }

      }

      &__part {
        th {
          color: white;
          padding: 5px;
          text-align: center;
        }

        &__nodes {
          &:hover {
            background: white;

            td {
              color: black !important;
            }
          }
        }
      }
    }
  }

  &__submit {
    margin-top: 30px;
  }
}

.dialog {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    margin: auto;
    background: white;
    border-bottom: 2px solid #EB5E28;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
    padding: 20px;

    small {
      color: rgba(0, 0, 0, 0.7);
      font-size: 10px;
      text-align: center;
      display: block;
    }

    &__header {
      display: flex;
      align-items: center;

      span {
        width: 120px;
        display: block;
        height: 1px;
        background: #EB5E28;
      }

      p {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #EB5E28;

        &[data-current-step] {
          background: #EB5E28;
          color: white;
        }
      }
    }

    &__progress {
      margin-top: 20px;
      text-align: center;

      &__first {
        margin-bottom: 20px;
        &:after {
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          animation: ellipsis steps(4, end) 900ms infinite;
          content: "\2026";
          width: 0;
        }
      }

      &__bar {
        border: 2px solid #EB5E28;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 0;
        position: relative;
        span {
          position: absolute;
          left: 0;
          top: 0;
          background: #EB5E28;
          height: 100%;
          display: block;
        }

        p {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

@keyframes ellipsis {
  to {
    width: 24px;
  }
}
