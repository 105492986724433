.workout {
  &__paragraph {
    border-bottom: 1px solid #2a2a2a;
    width: 100%;
    display: flex;
    column-gap: 20px;
    padding-top: 5px;
    padding-bottom: 5px;

    span {
      color: #31302e;
    }
  }

  &__player {
    margin-top: 20px;
    width: 100%;

    iframe {
      background: #252422;
      width: 100%;
      height: 480px;
    }

    &__parts {
      th {
        color: white;
        padding: 5px;
        text-align: center;
      }

      &__node {
        &:hover {
          background: white;

          td {
            color: black !important;
          }
        }
      }
    }
  }
}
