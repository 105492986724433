.billing {
  &__header {
    margin-bottom: 30px;
    text-align: center;
  }
  &__fieldset {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    column-gap: 30px;

    legend {
      margin-bottom: 5px;
      margin-left: 10px;
    }

    &_input {
      flex: 1 1 auto;
    }

    &__reset {
      height: 35px;
    }
  }

  &__prices {
    border-top: 1px solid #2a2a2a;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 20px;


    &__plan {
      border-bottom: 1px solid #2a2a2a;
      padding-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      row-gap: 10px;

      &_key {
        flex: 0 0 100%;
      }

      &__trials {
        margin-left: auto;
      }

      p {
        display: flex;
        align-items: center;
        column-gap: 20px;
        span {
          color: #2a2a2a;
        }
      }
    }
  }

  &__free_subs {
    border-top: 1px solid #2a2a2a;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 20px;

    &__checkbox_wrapper {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #EB5E28;
    }

    &__card_data {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      p {
        padding: 10px;
        flex: 0 0 100%;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      &__number {
        flex: 0 0 100%
      }

      &__date {
        flex: 0 0 20%
      }

      &__cvv {
        flex: 0 0 45%
      }
    }

    &__priceid {
      p {
        padding: 10px;
      }

      &__input {
        width: 100%;
      }
    }
  }
}
