.wrapper {
  flex: 0 1 auto;
  margin: 20px 20px;
  border-top: 5px solid #2a2a2a;
  background: white;
  overflow: hidden;
  width: 100%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);

  &__scrolled {
    width: 100%;
    padding: 20px 20px 20px 20px;
    height: 100%;
    overflow: auto !important;
  }
}
