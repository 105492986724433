.create {
  &_title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 20px;
  }

  &_input {
    width: 100%;
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    column-gap: 20px;

    button {
      flex: 1 1 auto;
    }
  }
}