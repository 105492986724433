.wrapper {
  border: none;
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, .3);

  &__content {
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, .3);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px 40px;
  }
}
